import axios from 'axios';
import {
  GET_HOMEPAGE_WINNERS_FAIL,
  GET_HOMEPAGE_WINNERS_REQUEST,
  GET_HOMEPAGE_WINNERS_SUCCESS,
} from './action.types';
import { apiUrl } from '../../api';

export const desktopWinnerAction = (featuredVal) => (dispatch) => {
  dispatch({
    type: GET_HOMEPAGE_WINNERS_REQUEST,
  });

  return axios
    .get(`${apiUrl}/api/auth/winner/featured/${featuredVal}`) //api/winners/desktop/1
    .then((res) => {
      dispatch({
        type: GET_HOMEPAGE_WINNERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_HOMEPAGE_WINNERS_FAIL,
        payload: err,
      });
    });
};
