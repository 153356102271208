import React from 'react';
import { Card, Col, Row, Avatar } from 'antd';
import styled from 'styled-components';
import Prize from '../../images/prize.png';
import Winner from '../../images/winner.png';
import Picker from '../../images/picler.png';

const { Meta } = Card;

export const MiniAbout = () => {
  const cardStyle = {
    borderRadius: '8px',
  };

  return (
    <Wrapper>
      <div id='about-cards'>
        <div className='container p-3'>
          <div className='general_title text-center  mb-5'>
            <h2 className='fw-bold'>Highlights of the Contest</h2>
            <hr className='center-square' />
          </div>
          <Row gutter={16}>
            <Col xs={24} xl={8}>
              <Card bordered={false} style={cardStyle}>
                <Meta
                  avatar={<Avatar src={Prize} />}
                  title='Contests Grand Prize'
                  description='Our Top 3 (WINNERS) most voted Contestants Photo will be awarded Cash Prize Respectively.'
                />
              </Card>
            </Col>
            <Col xs={24} xl={8}>
              <Card bordered={false} style={cardStyle}>
                <Meta
                  avatar={<Avatar src={Picker} />}
                  title='Nominations.'
                  description='Our 5 Highest voted Contestant Photos will be given an Open Window to Join the next TEENGIRLSUP Photo Contest Tournament.'
                />
              </Card>
            </Col>
            <Col xs={24} xl={8}>
              <Card bordered={false} style={cardStyle}>
                <Meta
                  avatar={<Avatar src={Winner} />}
                  // title='Lorem ipsum dolor sit amet.'
                  description='Our 3 Winners from TEENGIRLSUP first level Photo Contest will be qualified to contest in the next level contest which enables TEENGIRLSUP Management get a tournament winner.'
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  #about-cards {
    margin: 70px auto;
  }

  @media screen and (max-width: 1199px) {
    .ant-col-xs-24 {
      margin-bottom: 15px;
    }
  }
`;
