import React from 'react';
import styled from 'styled-components';
import Mission from '../../images/mission.png'
import Vision from '../../images/vision.png'

export const Blocktwo = () => {
  return (
    <Wrapper>
      <div id='why' className='mt-5 mb-5'>
        <div className='container  col-md-8 col-sm-8'>
          <div className='global_title text-center'>
            <h2>
              Why <em>TeenGirlsUp</em> ?
            </h2>
            <hr className='center-ball' />
          </div>
          <p>
            At <b>TEENGIRLSUP</b>, we welcome teen girls from all works of life
            as our aim is to build a healthy girls community where young girls
            appreciate and support each other by showing out the love in our
            heart to the people around us and even those we have never met as
            this will help illuminate the world with beauty.
          </p>
        </div>
      </div>

      <div className='envision mt-5 mb-5'>
        <div className='col-12 col-lg-12'>
          <div className='container'>
            <div className='heading_layer col-md-8 col-sm-8 mx-auto text-center m-3'>
              <div className='global_title text-center'>
                <h2>
                  What is your <em>magic</em> ?
                </h2>
                <hr className='center-diamond' />
              </div>
              <p>
                We believe every individual has an inborn gift that sets them
                apart from others and this gift lies in the uniqueness of
                individual values.
              </p>
              <span>
                We want to ignite this individuals values by putting reality in
                a pictorial representation and bringing together different
                consciousness to promote love and beauty.
              </span>
            </div>
            <div className='row d-flex'>
              <div className='col-md-6 col-sm-6  mx-auto m-3'>
                <div className='statement text-center'>
                  <img
                    className='img-fluid'
                    src={Vision}
                    alt='vision statement'
                  />
                  <h2 className='title mb-3 fw-bold'> Vision statement</h2>
                  <p>
                    To build a girl community where teen girls learn to live
                    life knowing they are limited to nothing but their mindset
                    and such thoughts must be erase.
                  </p>
                </div>
              </div>
              <div className='col-md-6 col-sm-6 mx-auto m-3 mb-5'>
                <div className='statement text-center'>
                  <img
                    className='img-fluid'
                    src={Mission}
                    alt='mission statement'
                  />
                  <h2 className='title mb-3 fw-bold'> Mission statement</h2>
                  <p>Growing love and building a better world of girls</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  #why,
  #what p,
  span {
    text-align: center;
    font-weight: normal;
    color: #4e4444;
  }

  .envision{
    background-color: #f5f5f5;
    padding: 50px 0;
  }
`;
