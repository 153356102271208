const slideItems = [
  {
    id: 1,
    imgUrl:
      'https://res.cloudinary.com/imoh-peter/image/upload/v1641260194/pexels-godisable-jacob-871495_1_golqqb.jpg',
    title: 'Welcome !!!',
    description:
      'Hey Sweetie!, We are building Our girls community, Please take a seat now and Build with us.',
    btn1Link: '/about',
    linkText: 'More Details',
    btn2Link: '/contact',
    btn2Text: 'Contact Us',
  },
  {
    id: 2,
    imgUrl:
      'https://res.cloudinary.com/imoh-peter/image/upload/v1641263380/pexels-ali-pazani-3061814_1_c8q6xk.jpg',
    title: 'We are TEENGIRLSUP!',
    description:
      'Experience a Limitless way of showing your beauty to the world in a simple and comfortable way by simple joining Our Online Photo Contest Community.',
    btn1Link: '/about',
    linkText: 'More Details',
    btn2Link: '/contact',
    btn2Text: 'Contact Us',
  },
  {
    id: 3,
    imgUrl:
      'https://res.cloudinary.com/imoh-peter/image/upload/v1641259802/pexels-moose-photos-1036623_1_zrwufj.jpg',
    title: 'Take Part in Our Photography Contest',
    description:
      'You are yet to realize boundless ways of Lighting the world with your beauty. Take Your Chance of living your dream life. Register now to join! .',
    btn1Link: '/register',
    linkText: 'Register Now',
    btn2Link: '/login',
    btn2Text: 'Sign In',
  },
  // {
  //   id: 4,
  //   imgUrl:
  //     'https://res.cloudinary.com/imoh-peter/image/upload/v1641259911/pexels-pixabay-247287_1_fbtxgm.jpg',
  //   title: 'One of the Best Photography Contest',
  //   description:
  //     'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti, iure exercitationem. Aliquam ex numquam consectetur.',
  //   btn1Link: '/about',
  //   linkText: 'More Details',
  //   btn2Link: '/contact',
  //   btn2Text: 'Contact Us',
  // },
];

export default slideItems;
