import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { footerLink } from './bottomLink';
import './beforeFooter.css';

const BeforeFooter = () => {
  return (
    <BeforeFooterWrapper id='before__footer'>
      <footer>
        <div className='footer-top-area pt-100 pb-100'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='single-footer footer-one'>
                  <h3 className='fw-bold'>About Company</h3>
                  <p>
                    <b>TEENGIRLSUP</b> is an online photo contest website set up
                    to raise a community of teen girls and letting them see the
                    light in their heart which signifies beauty{'...'}
                    <br />
                    <Link className='moreLink' to='/about'>
                      read more
                    </Link>
                  </p>
                  {/* <div className='footer-social-media-area'>
                    <nav>
                      <ul>
                        <li>
                          <a href='#'>
                            <i className='fa fa-facebook'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fa fa-twitter'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fa fa-rss'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fa fa-pinterest-p'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fa fa-linkedin'></i>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fa fa-instagram'></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
                </div>
              </div>

              <div className='col-lg-4 hidden-md col-sm-12'>
                <div className='single-footer footer-three'>
                  <h3 className='fw-bold'>Legals</h3>
                  <ul>
                    {footerLink.map((x) => {
                      return (
                        <li key={x.id} className='nav-links sitemap'>
                          <Link to={x.url}>{x.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='single-footer footer-four margin-0'>
                  <h3 className='fw-bold'>Corporate Office</h3>
                  <nav>
                    <ul>
                      <li className='sitemap'>
                        <a>
                          <i className='fa fa-paper-plane-o'></i> 44 New Design
                          Street, rne 00 USA
                        </a>
                      </li>
                      <li className='sitemap'>
                        <a href='tel:+985-2356-14566'>
                          <i className='fa fa-phone'></i> +985-2356-14566
                        </a>
                      </li>
                      <li className='sitemap'>
                        <a href='mailto:support@teengirlsup.com'>
                          <i className='fa fa-envelope-o'></i>{' '}
                          support@teengirlsup.com
                        </a>
                      </li>
                      <li className='sitemap'>
                        <a>
                          <i className='fa fa-fax'></i> Fax: (123) 4589761
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </BeforeFooterWrapper>
  );
};

const BeforeFooterWrapper = styled.section`
  background: #2d111f;
  background-size: cover;
  background-position: center center;
  padding: 80px 0;

  .moreLink {
    text-transform: capitalize;
    font-weight: 600;
    color: #dfdfdf;
  }

  .sitemap a {
    color: #dfdfdf;
    font-style: normal !important;
    text-decoration: none;
  }
  .sitemap a:hover {
    color: #cc2b2b;
  }
`;

export default BeforeFooter;
