import {
  GET_HOMEPAGE_WINNERS_FAIL,
  GET_HOMEPAGE_WINNERS_REQUEST,
  GET_HOMEPAGE_WINNERS_SUCCESS,
} from '../action/action.types';

const initialState = {
  winner: [],
  loading: false,
  error: null,
};

export const homepageWinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_WINNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOMEPAGE_WINNERS_SUCCESS:
      return {
        ...state,
        winner: action.payload,
        loading: false,
      };
    case GET_HOMEPAGE_WINNERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
