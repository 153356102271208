import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from 'react-multi-carousel';
import { desktopWinnerAction } from '../../store/action/winner.action';
import 'react-multi-carousel/lib/styles.css';
import './winner.css';

export const Winners = () => {
  const dispatch = useDispatch();

  let { contestList } = useSelector((state) => state.contest);
  const { loading, contestants, error } = useSelector(
    (state) => state.fetchContestants
  );
  const {
    loading: winnerLoading,
    winner,
    error: winnerError,
  } = useSelector((state) => state.winnersHomepage);

  useEffect(() => {
    dispatch(desktopWinnerAction(1));
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <React.Fragment>
      {winner.length !== 0 ? (
        <Winnerwrapper>
          <div id='winners' className='container mt-5  mb-5 md-5'>
            <div className='winner_title m-3'>
              <h2 className='title text-center'>
                Latest <em>Winners</em>
              </h2>
            </div>
            <div className='winner_list'>
              <Carousel
                swipeable={false}
                autoPlay
                draggable={false}
                showDots={!true}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition='all .5s ease-in-out'
                transitionDuration={500}
                containerClass='carousel-container'
                removeArrowOnDeviceType={['mobile']}
                dotListClass='custom-dot-list-style'
                itemClass='carousel-item-padding-40-px'
              >
                {winner && winner.length > 0 && (
                  winner.map((x, i) => {
                    const {
                      first_name,
                      last_name,
                      title,
                      profile_pic,
                      vote_count,
                    } = x;
                    console.log(i);
                    return (
                      <div className='card p-2 m-5 winner-card'>
                        <img
                          className='img-fluid img-thumbnail rounded-3'
                          src={profile_pic}
                          alt={last_name}
                        />
                        <div className='card-body'>
                          <p className='text-left m-0'>
                            <b>
                              {i === 0
                                ? 'Winner'
                                : i === 1
                                ? 'First Runner Up'
                                : i === 2
                                ? 'Second Runner Up'
                                : null}
                            </b>
                          </p>
                          <p className='text-left m-0'>
                            <strong>Name:</strong> {first_name} {last_name}
                          </p>
                          <p className='text-left m-0'>
                            <strong>Contest:</strong> {title}
                          </p>
                          <p className='text-left m-0'>
                            <strong>Vote Count:</strong> {vote_count}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) }
              </Carousel>
            </div>
          </div>
        </Winnerwrapper>
      ) : null}

      {/* {contestList &&
        contestList.length !== 0 &&
        contestList.map((ele) => {
          console.log(ele);
          const { id, title, end_time } = ele;

          const expiry = new Date(end_time.slice(0, 10));
          console.log(expiry);
          return <p>hello world</p>;
        })} */}
    </React.Fragment>
  );
};

const Winnerwrapper = styled.div`
  #winners .title {
    font-weight: bold;
    color: #000;
  }

  #winners .title em {
    color: #c70a90;
    font-style: normal;
  }
`;
