import React from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.css';

const Breadcumb = ({image, title, path, present}) => {
 
  return (
    <div
      id='breadcrumb'
      style={{
        backgroundImage: `linear-gradient(60deg,rgb(157 23 101 / 52%), #2125297d), url(${image})`,
      }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 title'>
            <h2>{title}</h2>
          </div>
          <div className='col-md-6'>
            <ul className='crumb'>
              <li>
                <Link to={path}>Home /</Link> {present}
              </li>
            </ul>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default Breadcumb;
