import React from 'react';
import styled from 'styled-components';
import Logo from '../../images/logo.png';

export const Blockone = () => {
  return (
    <Wrapper>
      <div id='aboutTeens' className='mt-5 mb-5'>
        <div className='global_title text-center'>
          <h2>
            Who we <em>are</em>
          </h2>
          <hr className='center-ball' />
        </div>
        <div className='row'>
          <div className='col-md-8 col-sm-12 mx-auto align-self-center'>
            <article className='teens'>
              <p className='1'>
                <b>TEENGIRLSUP</b> is an online photo contest website set up to
                raise a community of teen girls and letting them see the light
                in their heart which signifies beauty and uniqueness.
              </p>
              <p>
                At <b>TEENGIRLSUP</b>, your chance of winning the contest is
                determined by you, your team, family and friends who we believe
                should have a similar interest.
              </p>{' '}
            </article>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  #aboutTeens .logo {
    height: auto;
    width: 300px;
  }
  
  #aboutTeens h2 hr {
    border: 1px solid rgb(254, 11, 183);
  }

  #aboutTeens .teens p,
  span {
    color: #4e4444;
    text-align: center;
  }
`;
