import React, { Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Navbar from '../../components/layout/nav/Navbar';
import Breadcumb from '../../components/layout/breadcrumb/Breadcumb';
import Footer from '../../components/layout/footer/Footer';
import { resetPassword } from '../../store/action/auth.action';

const breadcrumbImg = `https://images.unsplash.com/photo-1517817748493-49ec54a32465?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVnaXN0ZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60`;
export const PswdRecovery = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { loading, resetSuccess, error } = useSelector(
    (state) => state.recovery
  );

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ email, newPassword, confirmPassword }));
  };

  useEffect(() => {
    $(document).ready(function () {
      $('#show_hide_password a').on('click', function (event) {
        event.preventDefault();
        if ($('#show_hide_password input').attr('type') == 'text') {
          $('#show_hide_password input').attr('type', 'password');
          $('#show_hide_password i').addClass('fa-eye-slash');
          $('#show_hide_password i').removeClass('fa-eye');
        } else if ($('#show_hide_password input').attr('type') == 'password') {
          $('#show_hide_password input').attr('type', 'text');
          $('#show_hide_password i').removeClass('fa-eye-slash');
          $('#show_hide_password i').addClass('fa-eye');
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Breadcumb
        title='Password Recovery'
        image={breadcrumbImg}
        path='/'
        present='Password Recovery'
      />
      <StyledPswdRecovery>
        <div id='pswd_reset' className='m-5'>
          <div className='container'>
            <div id='pswd_reset_form'>
              <div className='card col-md-6 col-sm-6 mx-auto'>
                <div className='card-body'>
                  <div className='p-4'>
                    <div className='text-center'>
                      <h5 className=' mb-3'>Password Recovery</h5>
                      {error ? (
                        <div className='alert alert-danger'>
                          <strong>{error.error}</strong>
                        </div>
                      ) : null}
                      {resetSuccess ? (
                        <div className='alert alert-success'>
                          <strong>{resetSuccess.msg}</strong>
                        </div>
                      ) : null}
                      <form
                        className='form-horizontal'
                        role='form'
                        onSubmit={handleSubmit}
                      >
                        <div className='form-group'>
                          <input
                            type='email'
                            className='form-control form-control-lg mb-3'
                            id='email'
                            placeholder='Email'
                            required='required'
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div class='input-group mb-3' id='show_hide_password'>
                          <input
                            class='form-control form-control-lg'
                            type='password'
                            name='newPassword'
                            placeholder='New Password'
                            required='required'
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <div
                            class='input-group-addon border-1 rounded p-2'
                            style={{
                              border: '1px solid #ced4da',
                              borderLeft: 'none',
                              background: '#efefef',
                            }}
                          >
                            <a>
                              <i
                                class='fa fa-eye-slash'
                                aria-hidden='true'
                                style={{ color: '#712d50' }}
                              ></i>
                            </a>
                          </div>
                        </div>

                        <div class='input-group mb-3' id='show_hide_password'>
                          <input
                            class='form-control form-control-lg'
                            type='password'
                            name='confirmPassword'
                            placeholder='Confirm New Password'
                            required='required'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div
                            class='input-group-addon border-1 rounded p-2'
                            style={{
                              border: '1px solid #ced4da',
                              borderLeft: 'none',
                              background: '#efefef',
                            }}
                          >
                            <a>
                              <i
                                class='fa fa-eye-slash'
                                aria-hidden='true'
                                style={{ color: '#712d50' }}
                              ></i>
                            </a>
                          </div>
                        </div>

                        <div className='form-group'>
                          <button
                            type='submit'
                            className='btn btn-success btn-purple btn-purple-modified p-3 w-100'
                          >
                            {loading ? (
                              <div className='d-flex justify-content-center'>
                                <div className='spinner-border' role='status'>
                                  <span className='sr-only'></span>
                                </div>
                              </div>
                            ) : (
                              'Reset password'
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledPswdRecovery>
      <Footer />
    </Fragment>
  );
};

const StyledPswdRecovery = styled.div``;
