import React from 'react';
import {
  MdOutlineDashboardCustomize,
  AiFillEdit,
  AiFillTrophy,
  ImHome,
  AiFillProfile,
  GiPodiumWinner,
  MdContactPhone,
  VscLaw,
} from 'react-icons/all';

import About from './containers/about/About';
import { Contact } from './containers/contact/Contact';
import Contest from './containers/contest/Contest';
import Contestant from './containers/contestant/Contestant';
import DashboardNew from './containers/dashboard/DashboardNew';
import Vote from './containers/vote/Vote';
import WriteUser from './containers/Edit/WriteUser';
import Index from './containers/gallerry/Index';
import Home from './containers/Home/Index';
import { Login } from './containers/login/Index';
import { Register } from './containers/registration/Register';
import Terms_and_Conditon from './containers/terms&condition/Terms_and_Conditon';
import { PswdRecovery } from './containers/recovery/PswdRecovery';

const routeData = [
  { id: 1, path: '/', name: 'Home', component: <Home /> },
  { id: 2, path: '/login', name: 'Login', component: <Login /> },
  { id: 3, path: '/register', name: 'Register', component: <Register /> },
  { id: 4, path: '/about', name: 'About', component: <About /> },
  { id: 5, path: '/gallery', name: 'Gallery', component: <Index /> },
  { id: 6, path: '/contact', name: 'Contact', component: <Contact /> },
  { id: 7, path: '/contestants', name: 'Contestants', component: <Contestant />},
  { id: 7, path: '/vote/:id', name: 'Vote', component: <Vote />},
  { id: 8, path: '/terms_&_conditions', name: 'Terms And Conditions', component: <Terms_and_Conditon /> },
  {id: 10, path: '/reset-password', name: 'Reset Password', component: <PswdRecovery />},
];

const privateRouteData = [
  {
    id: 1,
    path: '/dashboard',
    name: 'Dashboard',
    component: <DashboardNew />,
    icon: <MdOutlineDashboardCustomize className='sidenav_svg' />,
  },
  {
    id: 2,
    path: '/edit-profile',
    name: 'Edit Profile',
    component: <WriteUser />,
    icon: <AiFillEdit className='sidenav_svg' />,
  },
  {
    id: 3,
    path: '/contest',
    name: 'Contest',
    component: <Contest />,
    icon: <AiFillTrophy className='sidenav_svg' />,
  },
];

const statics = [
  {
    id: 1,
    path: '/',
    name: 'Home',
    icon: <ImHome className='sidenav_svg' />,
  },
  {
    id: 2,
    path: '/about',
    name: 'About',
    icon: <AiFillProfile className='sidenav_svg' />,
  },
  {
    id: 3,
    path: '/contestants',
    name: 'Contestants',
    icon: <GiPodiumWinner className='sidenav_svg' />,
  },
  {
    id: 4,
    path: '/contact',
    name: 'Contact',
    icon: <MdContactPhone className='sidenav_svg' />,
  },
  {
    id: 5,
    path: '/terms_&_conditions',
    name: 'Terms and Conditions',
    icon: <VscLaw className='sidenav_svg' />,
  },
];

export { routeData, privateRouteData, statics };
