import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Breadcumb from '../../components/layout/breadcrumb/Breadcumb';
import BeforeFooter from '../../components/layout/footer/BeforeFooter';
import Footer from '../../components/layout/footer/Footer';
import Navbar from '../../components/layout/nav/Navbar';
import { loginUser } from '../../store/action/auth.action';
import './login.css';

export const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated, loading, user, error } = login;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }));
  };

  const breadcrumbImg =
    'https://images.unsplash.com/photo-1638208561774-6e02a8e17cc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1NHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60';

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/dashboard');
      //  window.location.href = '/dashboard';
    }

    $(document).ready(function () {
      $('#show_hide_password a').on('click', function (event) {
        event.preventDefault();
        if ($('#show_hide_password input').attr('type') == 'text') {
          $('#show_hide_password input').attr('type', 'password');
          $('#show_hide_password i').addClass('fa-eye-slash');
          $('#show_hide_password i').removeClass('fa-eye');
        } else if ($('#show_hide_password input').attr('type') == 'password') {
          $('#show_hide_password input').attr('type', 'text');
          $('#show_hide_password i').removeClass('fa-eye-slash');
          $('#show_hide_password i').addClass('fa-eye');
        }
      });
    });
  }, [login]);

  return (
    <React.Fragment>
      <Navbar />
      <Breadcumb image={breadcrumbImg} title='Login' path='/' present='Login' />
      <div id='login'>
        <div className='container'>
          <div className='login-form'>
            <center>
              {' '}
              <div className='card container col-md-6 col-lg-6 col-sm-6 pt-4 pb-4'>
                <div className='card-body'>
                  <h5 className='lead card-title'>
                    <strong>Sign In</strong>
                  </h5>
                  <br />
                  {/* <hr className='line' /> */}
                  <div className='loginForm'>
                    {error && (
                      <small className='text-danger'>{error.error}</small>
                    )}
                    <div className='container'>
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <div className='form-group'>
                          <input
                            type='email'
                            className='form-control form-control-lg p-3  mb-3'
                            placeholder='Enter e-mail'
                            onChange={(e) =>
                              setEmail(e.target.value.toLowerCase())
                            }
                          />

                          <div class='input-group mb-3' id='show_hide_password' style={{flexWrap: 'nowrap'}}>
                            <input
                              class='form-control form-control-lg '
                              type='password'
                              placeholder='Enter password'
                              onChange={(e) => setPassword(e.target.value)}
                              style={{ width: 'auto' }}
                            />
                            <div
                              class='input-group-addon border-1 rounded p-2'
                              style={{
                                border: '1px solid #ced4da',
                                borderLeft: 'none',
                                background: '#efefef',
                                width: 'auto',
                              }}
                            >
                              <a>
                                <i
                                  class='fa fa-eye-slash'
                                  aria-hidden='true'
                                  style={{ color: '#712d50' }}
                                ></i>
                              </a>
                            </div>
                          </div>

                          <button
                            className='btn btn-success btn-purple btn-override p-3 w-100 mb-3'
                            type='submit'
                          >
                            {loading ? (
                              <div className='d-flex justify-content-center'>
                                <div className='spinner-border' role='status'>
                                  <span className='sr-only'></span>
                                </div>
                              </div>
                            ) : (
                              'sign in'
                            )}
                          </button>

                          <Link
                            to='/reset-password'
                            className='btn btn-dark p-3 w-100'
                          >
                            Forget Password
                          </Link>
                        </div>
                        <p className='m-4 text-small text-center'>
                          not registered?{' '}
                          <span>
                            <Link className='text-primary' to='/register'>sign up</Link>
                          </span>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
      {/* <BeforeFooter/> */}
      <Footer />
    </React.Fragment>
  );
};
